import React from 'react';
import { get, isNil } from "utils/lodashExtracts";
import { Layout } from 'components/common';
import Segment from "utils/segment";
import CharlesMeta from 'components/common/meta/CharlesMeta';
import StructuredWebPage from 'components/common/meta/StructuredWebPage';
import TopSectionHub from 'templates/Pathologies/common/TopSectionHub';
import Cta from 'components/common/Cta';
import FaqPathology from "templates/Pathologies/common/FaqPathology";
import PathologiesList from "templates/Pathologies/Mother/PathologiesList";
import MotherSurFooter from 'templates/Pathologies/common/MotherSurFooter';
import { pathologiesMotherBreadcrumbs } from "templates/Blog/common/Breadcrumb";
import T from "types/index";

import "./styles.sass";
import StructuredFAQ from 'components/common/meta/StructuredFAQ';
import { graphql } from 'gatsby';

type Props = {
  data: {
    categoryRecord: T.Charles.CategoryRecord
  },
}
const Mother = ({ data }: Props) => {
  const category: T.Charles.Category = data.categoryRecord.data
  const templateData: T.Charles.MotherTemplateData = get(category, 'mother_template_data[0].data')
  const cta = <Cta
    text={templateData.cta_label}
    className="new-primary-cta pathology-cta"
    path={templateData.start_slug}
  />
  const metadata: T.Charles.Metadata = get(category, "metadata[0].data");

  const itemsToQA = (item: T.Charles.ItemRecord) => ({
    question: isNil(item.data.title) ? '' : item.data.title.childMarkdownRemark.rawMarkdownBody,
    answer: isNil(item.data.text) ? '' : item.data.text.childMarkdownRemark.rawMarkdownBody,
  })
  const faqQA = templateData.faq_items.map(itemsToQA)
  return (
    <Layout
      showLinksInFooter={false}
      footerCtaLabel={templateData.cta_label}
    >
      <CharlesMeta
        title={metadata.title}
        description={metadata.meta_description}
        segmentCategory={Segment.CATEGORIES.PATHOLOGIES}
      />
      <StructuredWebPage
        title={templateData.h1.internal.content}
        description={metadata.meta_description}
      />
      <StructuredFAQ data={faqQA} />
      <div className="pathology-template" id="gm-template">
        <TopSectionHub
          h1={templateData.h1}
          subtitle={templateData.subtitle}
          cta_label={templateData.cta_label}
          start_slug={templateData.start_slug}
          main_image={templateData.main_image}
          reinsurance_items={templateData.reinsurance_items}
          cta={cta}
          imageClassName='pathologies-mother-top-section-image'
          headingClassName='pathologies-mother-head-section'
        />
        <PathologiesList
          category={category}
          pathologies_title={templateData.pathologies_title}
          pathologies_subtitle={templateData.pathologies_subtitle}
        />
        <div className='pathologies-mother-faq'>
          <div className="container">
            <div className="pathologies-mother-hr"></div>
          </div>
          <FaqPathology
            start_slug={templateData.start_slug}
            faq_title={templateData.faq_title}
            faq_items={templateData.faq_items}
            videos={[]}
            cta={cta}
          />
        </div>
      </div>
      <MotherSurFooter
        categoryRecord={data.categoryRecord}
        breadcrumbLinks={pathologiesMotherBreadcrumbs(category)}
        className="pathologies-mother-surfooter"/>
    </Layout>
  )
};

export default Mother;


export const pageQuery = graphql`
  query ($id: String!) {
    categoryRecord: airtableCategories(id: { eq: $id }) {
      id
      data {
        slug
        name
        oms_slug
        metadata {
          ...AirtableMetadataFields
        }
        pathologies {
          id
          data {
            slug
            breadcrumb_label
            status
            environment
            thumbnail { ...AirtableImagesConstrainedFields }
          }
        }
        mother_template_data {
          ...AirtableMotherTemplateDataFields
        }
      }
    }
  }
`;
